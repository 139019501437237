/* line 3, /runap/frontend/app/sass/header.scss */
div.page-header > div.page-header-top div.pnn-header-container1 {
  display: flex;
  justify-content: center;
  background-color: #36c;
  padding: 8px 0; }
  /* line 9, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    align-items: center; }
    /* line 16, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane {
      display: flex;
      margin-left: 22px; }
      /* line 20, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-govco {
        align-self: center;
        height: 40px;
        content: url(https://components.parquesnacionales.gov.co/shared/logo-govco.svg); }
      /* line 26, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-separator {
        align-self: center;
        height: 44px;
        border-left: 1px solid #e5eefb;
        margin-right: 5px; }
      /* line 67, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
        height: 40px;
        align-self: center;
        display: none;
        content: url(/img/logos/runap.png);
        margin-top: 2px;
        margin-left: 10px; }
    /* line 77, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane {
      margin-right: 25px; }
      /* line 80, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane > a.menu-toggler {
        align-self: center; }

/* line 87, /runap/frontend/app/sass/header.scss */
div.page-header > div.page-header-top div.pnn-header-container2 {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 2rem 0; }
  /* line 93, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    width: 100%; }
    /* line 101, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane {
      display: flex;
      row-gap: 20px;
      margin-left: 23px; }
      /* line 106, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-potencia-vida {
        height: 50px;
        content: url(https://components.parquesnacionales.gov.co/shared/potencia-vida.png);
        margin-right: 5px;
        margin-left: 5px; }
      /* line 113, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-entidad {
        height: 50px;
        content: url(https://components.parquesnacionales.gov.co/shared/logo-pnn.png);
        margin-right: 15px;
        margin-left: 11px; }
    /* line 121, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane {
      margin-left: 35px;
      margin-right: 35px; }
      /* line 125, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane > div.search-form {
        margin-top: 5px; }

/* line 138, /runap/frontend/app/sass/header.scss */
div.page-header div.page-header-menu > div.container > div.hor-menu > ul.navbar-nav > li.menu-dropdown a {
  color: #004884; }

@media (min-width: 768px) {
  /* line 171, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 {
    padding: 5px 0; }
    /* line 178, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
      display: flex !important; } }

@media (min-width: 992px) {
  /* line 198, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 {
    justify-content: center !important;
    padding: 5px 0; }
    /* line 202, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container {
      width: 940px !important; }
      /* line 206, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
        /* line 209, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
          display: flex !important; }
      /* line 214, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; }
  /* line 220, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container2 {
    justify-content: center !important; }
    /* line 223, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container {
      width: 940px !important; }
      /* line 227, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
      /* line 231, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; } }

@media (min-width: 1200px) {
  /* line 244, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container1 {
    justify-content: center !important;
    padding: 5px 0; }
    /* line 248, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container {
      width: 1140px !important; }
      /* line 252, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
        /* line 255, /runap/frontend/app/sass/header.scss */
        div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.left-pane > div.pnn-header-logo-runap {
          display: flex !important; }
      /* line 260, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container1 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; }
  /* line 266, /runap/frontend/app/sass/header.scss */
  div.page-header > div.page-header-top div.pnn-header-container2 {
    justify-content: center !important; }
    /* line 269, /runap/frontend/app/sass/header.scss */
    div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container {
      width: 1140px !important; }
      /* line 273, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.left-pane {
        margin-left: 10px !important; }
      /* line 277, /runap/frontend/app/sass/header.scss */
      div.page-header > div.page-header-top div.pnn-header-container2 > div.pnn-header-inner-container > div.right-pane {
        margin-right: 20px !important; } }
